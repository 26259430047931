import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ListItem } from '@material-ui/core'

export const ListContainer = styled.div`
  && {
    min-width: 80px;
    border: none;
  }
`

export const ItemNumb = styled.span`
  && {
    background: #fff;
    right: 10px;
    font-size: 0.5em !important;
    padding: 0.5em;
    border-radius: 50%;
    position: absolute;
    color: #2d2e3c !important;
    display: block !important;
    min-width: 1.3em;
  }
`

export const StyledDrawer = styled.div`
  && {
    min-width: 80px;
    left: 0;
    top: 0;
    border: none;
    background: #ffffff;
    position: fixed;
    z-index: 300;

    box-shadow: 6px 0 9px 0 rgba(0, 0, 0, 0.4);
    /* box-shadow: 4px 0 9px 0 rgba(0, 0, 0, 0.06); */

    & > div {
      border: none;
      width: 80px;
      height: 100%;
    }
    /* & > div > div {
      display: none;
    }
    &:hover > div > div {
      display: block;
    } */
    @media print {
      display: none;
    }
  }
`
export const StyledListItem = styled.li`
  && {
    text-align: center;
    display: block;
    position: relative;
    transition: all 0.2s ease-in;
    padding: 0.8em 0;
    text-decoration: none;
    color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    &:last-child {
      border-bottom: none;
    }
    span {
      display: none;
    }
    &:hover {
      text-align: center;
      display: block;
      position: relative;
      border-left: 6px solid #fff;
      text-decoration: none;
      padding: 0.8em 0;
      background: rgba(255, 255, 255, 0.1);
      i {
        color: #fff;
      }
      span {
        color: #fff;
        font-size: 0.7em;
      }
    }
  }
`

export const StyledActiveListItem = styled(Link)`
  && {
    text-align: center;
    display: block;
    position: relative;
    border-left: 6px solid #fff;
    text-decoration: none;
    padding: 0.8em 0;
    background: rgba(255, 255, 255, 0.1);
    i {
      color: #fff;
    }
    span {
      color: #fff;
      font-size: 0.7em;
    }
  }
`

export const MenuIcon = styled.i`
  && {
    width: 100%;
    display: block;
    font-size: 1.3em;
    text-align: center;
    padding: 0.4em 0;
    color: rgba(255, 255, 255, 1);
  }
`

export const StyledLogo = styled(ListItem)`
  && {
    margin-bottom: 1em;
    padding: 1em 0;
    text-align: center;
    cursor: pointer;
    /* background: url('../../images/logo.svg'); */
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    color: #fff;
    font-weight: bold;
    font-size: 1.2em;
    img {
      /* width: 45%; */
      max-height: 55px;
    }
  }
`

export const StyledListWrap = styled.div`
  && {
    display: block;
    width: 100%;
    overflow: auto;
    padding: 0;
    margin: 0;
    -webkit-overflow-scrolling: touch;
  }
`

export const StyledList = styled.div`
  && {
  }
`

export const StyledNavigationPanel = styled.div`
  && {
    border: none;
    z-index: 300;
    > div {
      background: #313140;
    }
    div {
      background: ${props => props.background};
    }
  }
`
