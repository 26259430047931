import { toast } from 'react-toastify'
import { createGlobalState } from 'react-hooks-global-state'
import { presistData, getPresistData } from './util'

let thisState
//lets load user data for reload
if (typeof localStorage !== 'undefined' && localStorage.editcors) {
  thisState = JSON.parse(localStorage.editcors)
}

const initialState = {
  pageClass: 'login',
  projects: [],
  projectTypes: [],
  resources: [],
  availableResources: [],
  resourceTypes: [],
  users: [],
  messages: [],
  userHistory: [],
  projectHistory: [],
  resourcesHistory: [],
  resourceHistory: [],
  availableUsers: [],
  userTypes: [],
  socket: null,
  remoteStated: false,
  loggedIn: false,
  relations: [],
  setLoggedIn: null,
  ...thisState
}

const { useGlobalState, setGlobalState, getGlobalState } = createGlobalState(
  initialState
)

const setStorageState = (type, data) => {
  const thisState = getPresistData('editcors')
  presistData('editcors', { ...thisState, [type]: data })
  setGlobalState(type, data)
}

export { useGlobalState, setGlobalState, getGlobalState, setStorageState }
