import styled from 'styled-components'

export const HeaderContainer = styled.div`
  && {
    min-width: 80px;
    margin-left: 80px;
    margin-top: 1.5em;
    div {
      justify-items: center;
      align-items: center;
    }

    h1 {
      margin-top: 0em;
      font-size: 2em;
      font-weight: bold;
      padding: 0;
    }
  }
`
