import { toast } from 'react-toastify'
import { setGlobalState, getGlobalState } from './store'
import { getPresistData } from './util'
import openSocket from 'socket.io-client'

export const setRemoteCall = (callType, data, message) => {
  const socket = getGlobalState('socket')
  console.log(callType, data, message)
  socket.emit(callType, data)
  if (message) toast(`Requesting from the server ${message}`)
}

export const socketListeners = selfSocket => {
  const socket = getGlobalState('socket')
  if (!socket) return null

  ///get initial data
  socket.emit('getInitData')
  toast('Dados iniciais obtidos')

  socket.on('users', received => {
    setGlobalState('users', received || [])
    //let filter out available resources
    // if (received) {
    //   const availableUsers = received.filter(user => user.status === 1) || []
    //   setGlobalState('availableUsers', availableUsers)
    // }
    // toast(`Got ${received.length} users`)
  })
  socket.on('messages', received => {
    setGlobalState('messages', received || [])
  })
  socket.on('resourcesHistory', received => {
    setGlobalState('resourcesHistory', received || [])
  })
  socket.on('usersAvailable', received => {
    setGlobalState('availableUsers', received || [])
    // toast(`Got ${received.length} users`)
  })
  socket.on('userTypes', received => {
    setGlobalState('userTypes', received || [])
  })
  socket.on('userHistory', received => {
    setGlobalState('userHistory', received || [])
  })
  socket.on('projects', received => {
    setGlobalState('projects', received || [])
    // toast(`Got ${received.length} projects`)
  })
  socket.on('projectHistory', received => {
    setGlobalState('projectHistory', received || [])
  })
  socket.on('projectTypes', received => {
    setGlobalState('projectTypes', received || [])
  })
  socket.on('resources', received => {
    setGlobalState('resources', received || [])
    // toast(`Got ${received.length} resources`)
    //let filter out available resources
    // if (received) {
    //   const availableResources =
    //     received.filter(resource => resource.status === 1) || []
    //   setGlobalState('availableResources', availableResources)
    // }
  })
  socket.on('resourcesAvailable', received => {
    setGlobalState('availableResources', received || [])
  })
  socket.on('resourceHistory', received => {
    setGlobalState('resourceHistory', received || [])
  })
  socket.on('resourceTypes', received => {
    setGlobalState('resourceTypes', received || [])
  })
  socket.on('relations', received => {
    setGlobalState('relations', received || [])
  })
}
export const checkAuthRoute = redirectIfLoggedin => {
  const loggedIn = getGlobalState('loggedIn')
  if (
    !loggedIn &&
    !getPresistData('editcors', 'loggedIn') &&
    window.location.pathname !== '/'
  ) {
    return (window.location = '/')
  } else if (
    redirectIfLoggedin &&
    loggedIn &&
    getPresistData('editcors', 'loggedIn')
  ) {
    return (window.location = '/dashboard')
  }
  return true
}

export const userLoggedIn = async () => {
  const loggedIn = getGlobalState('loggedIn')
  if (!loggedIn) return null
  const selfSocket = await openSocket('https://api.editcors.com', {
    upgrade: false,
    query: `token=${loggedIn.token}`,
    extraHeaders: { Authorization: `Bearer ${loggedIn.token}` }
    // transports: ['websocket']
  })
  selfSocket.on('unauthorized', (error, callback) => {
    console.log(error, callback)
    if (
      error.data.type == 'UnauthorizedError' ||
      error.data.code == 'invalid_token'
    ) {
      // redirect user to login page perhaps or execute callback:
      console.log('User token has expired')
      return (window.location = '/')
    }
  })
  setGlobalState('socket', selfSocket)
  socketListeners()
}
