import React from 'react'
import { ThemeProvider, CSSReset } from '@chakra-ui/core'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { useGlobalState } from '../store'

import { userLoggedIn, checkAuthRoute } from '../remote'

import './App.css'
import customTheme from '../theme'
import Page from '../components/page'
import Login from '../login/Login'
import Reset from '../login/Reset'
import Users from '../users/Users'
import Messages from '../messages/Messages'
import Dashboard from '../dashboard/Dashboard'
import History from '../history/History'
import WatchAuth from '../auth/WatchAuth'
import Resources from '../resources/Resources'

export default function App() {
  //we need this to rerender the app upon login
  const [loggedIn, setLoggedIn] = useGlobalState('loggedIn') //dont remove
  // const loggedIn = getGlobalState('loggedIn')
  userLoggedIn()

  return (
    <ThemeProvider theme={customTheme}>
      <CSSReset />

      <Router>
        <WatchAuth>
          <Page>
            <Switch>
              <Route
                exact
                path="/reset/:token"
                component={() => (
                  <Reset
                  // setStorageState={this.setStorageState}
                  />
                )}
              />
              <Route
                exact
                path="/users"
                component={() =>
                  checkAuthRoute() && (
                    <Users
                    // data={users}
                    // types={userTypes}
                    // setRemoteCall={setRemoteCall}
                    // setStorageState={this.setStorageState}
                    />
                  )
                }
              />
              <Route
                exact
                path="/messages"
                component={() =>
                  checkAuthRoute() && (
                    <Messages
                    // data={users}
                    // types={userTypes}
                    // setRemoteCall={setRemoteCall}
                    // setStorageState={this.setStorageState}
                    />
                  )
                }
              />
              <Route
                exact
                path="/history"
                component={() =>
                  checkAuthRoute() && (
                    <History
                    // data={resources}
                    // types={resourceTypes}
                    // setRemoteCall={setRemoteCall}
                    // setStorageState={this.setStorageState}
                    />
                  )
                }
              />
              <Route
                exact
                path="/dashboard"
                component={() =>
                  checkAuthRoute() && (
                    <Resources
                    // data={resources}
                    // types={resourceTypes}
                    // setRemoteCall={setRemoteCall}
                    // setStorageState={this.setStorageState}
                    />
                  )
                }
              />
              <Route
                exact
                path="/"
                component={() =>
                  checkAuthRoute(true) && (
                    <Login
                    // setStorageState={this.setStorageState}
                    // setRemoteCall={setRemoteCall}
                    />
                  )
                }
              />
            </Switch>
          </Page>
        </WatchAuth>
      </Router>
      <ToastContainer
        position="bottom-right"
        hideProgressBar={true}
        closeOnClick
      />
    </ThemeProvider>
  )
}
