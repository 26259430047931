import React, { useState } from 'react'
import Header from '../components/header'
import Edit from './Edit'
import { Grid, Box, Button } from '@chakra-ui/core'
import { useGlobalState, setGlobalState } from '../store'
import { setRemoteCall } from '../remote'
import TableProvider from '../components/Table/Table'

/**
 * Table collumns
 */
export const tableCollumns = [
  { k: '_id', l: '_id' },
  { k: 'name', l: 'Name', required: true },
  { k: 'url', l: 'Url', required: true },
  { k: 'type', l: 'Type', required: true },
  { k: 'content', l: 'Content', t: 'textarea' },
  { k: 'status', l: 'State', required: true },
  { k: 'seo', l: 'SEO' },
  { k: 'attr', l: 'Atributes' },
  { k: 'startDate', l: 'Start Date', t: 'datetime' },
  { k: 'lastUpdate', l: 'Last Updated', t: 'datetime' },
  { k: 'author', l: 'Author' }
]
export const typesCollumns = [
  { k: '_id', l: '_id' },
  { k: 'name', l: 'Name', required: true },
  { k: 'details', l: 'Details', t: 'textarea' },
  { k: 'color', l: 'Color', t: 'color' },
  { k: 'status', l: 'State', required: true }
  // { k: 'attr', l: 'Atributos' },
]

export default function History(props) {
  /**
   * Get Data
   */
  const [openModal, setOpenModal] = useState(false)
  const [view, setView] = useState('resources')
  const [data, setData] = useGlobalState('resourcesHistory')
  const [types, setTypes] = useGlobalState('resourceTypes')
  const [authors, setAuthors] = useGlobalState('users')
  //set the initical class name
  setGlobalState('pageClass', 'resources')

  const collumns = view === 'resources' ? tableCollumns : typesCollumns

  /**
   * Table data
   */
  const resources = view === 'resources' ? data : types

  /**
   * Table options
   */
  const options = {
    filter: true,
    filterType: 'dropdown',
    count: resources ? resources.length : 0,
    downloadOptions: { filename: 'report.csv', separator: ';' },
    tableBodyHeight: 'calc( 100vh - 260px )',

    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      const selectedIds = selectedRows.data.map(
        sel => resources[sel.dataIndex]._id
      )

      const actionType =
        view === 'resources' ? 'delResourcesHistory' : 'delResourceTypes'
      const actionMessage =
        view === 'resources' ? ` Deleted Resources` : ` Deleted resources types`

      return (
        <div className="table-actions">
          <Button
            variantColor="red"
            onClick={() =>
              setRemoteCall(actionType, selectedIds, actionMessage)
            }
          >
            Delete Selected
          </Button>
        </div>
      )
    },
    onRowClick: (rowData, rowMeta) => {
      setOpenModal(resources[rowMeta.dataIndex])
    }
  }

  /**
   * Set table data
   */
  const set = (data, notice) => {
    setRemoteCall(
      view === 'resources' ? 'setResourcesHistory' : 'setResourceTypes',
      data,
      notice
    )
  }

  return (
    <div className={'table-wrapper'}>
      <Box w="100%" className={'content'} padding={'2em'}>
        {' '}
        <Header>
          {/* <button onClick={() => props.socket.emit('page', 'games')}>
          Isto é o que está a acontecer hoje
        </button> */}
          <Grid templateColumns="repeat(2, 1fr)" gap={6}>
            <Box w="100%">
              <h1>History</h1>
            </Box>

            <Box w="100%" h="10">
              {view === 'resources' && (
                <Button
                  active={props.periodType === 'types'}
                  width={'50%'}
                  onClick={() => setView('types')}
                >
                  <span className={`text`}>
                    {' '}
                    <span className={`icon-book-open icons`} /> Types
                  </span>
                </Button>
              )}
              {view === 'types' && (
                <Button
                  active={props.periodType === 'resources'}
                  width={'50%'}
                  onClick={() => setView('resources')}
                >
                  <span className={`text`}>
                    <span className={`icon-wrench icons`} /> Resources
                  </span>
                </Button>
              )}
              <Button
                active={props.periodType === 'week'}
                width={'49%'}
                marginLeft={'1%'}
                variantColor="green"
                onClick={() => setOpenModal({})}
              >
                <span className={`text`}>
                  <span className={`icon-plus icons`} /> {'Add'}
                </span>
              </Button>
            </Box>
          </Grid>
        </Header>
        {resources && (
          <div className={'contentTableMap'}>
            <div className={'tableWrapper'}>
              <TableProvider
                data={resources}
                columns={collumns.map(key => {
                  if (key.k === 'status') {
                    return {
                      name: key.k,
                      label: key.l,
                      selector: key.k,
                      options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                          switch (Number(value)) {
                            case 0:
                              return 'Disabled'
                            case 1:
                              return 'Active'
                            case 2:
                              return 'Alocated'
                            default:
                              break
                          }
                        }
                      }
                    }
                  }
                  if (key.k === 'type') {
                    return {
                      name: key.k,
                      label: key.l,
                      selector: key.k,
                      options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                          if (types && value) {
                            const thisType = types.find(t =>
                              value.includes(t._id)
                            )
                            return thisType && thisType.name
                          }
                          return ''
                        }
                      }
                    }
                  }
                  if (key.k === 'author') {
                    return {
                      name: key.k,
                      label: key.l,
                      selector: key.k,
                      options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                          if (authors && value) {
                            const thisAuthor = authors.find(t =>
                              value.includes(t._id)
                            )
                            return thisAuthor && thisAuthor.domain
                          }
                          return ''
                        }
                      }
                    }
                  }
                  return {
                    name: key.k,
                    label: key.l,
                    selector: key.k,
                    options: {
                      filter: true,
                      sort: true,
                      display:
                        key.k !== '_id' &&
                        key.k !== '__v' &&
                        key.k !== 'content' &&
                        key.k !== 'attr' &&
                        key.k !== 'startDate' &&
                        key.k !== 'seo'
                    }
                  }
                })}
                options={options}
              />
            </div>
          </div>
        )}
        {openModal && (
          <Edit
            data={openModal}
            form={collumns}
            set={set}
            types={types}
            setOpenModal={setOpenModal}
          />
        )}
      </Box>
    </div>
  )
}
