import React, { useEffect, useState } from 'react'
import {
  Button,
  Select,
  InputLeftAddon,
  InputGroup,
  Input,
  Textarea,
  Text
} from '@chakra-ui/core'

import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton
} from '@chakra-ui/core'
import { getGlobalState, useGlobalState } from '../store'
import { toast } from 'react-toastify'

export default function Edit(props) {
  const [details, setDetails] = useState(props.data || {})
  const [location, setLocation] = useState(
    props.data ? props.data.location : {}
  )
  const [view, setView] = useState('info')
  const projects = getGlobalState('projects')
  const relations = getGlobalState('relations')

  let thisProject = {}

  const thisRel = relations.find(rel => {
    return rel.accountId.includes(details._id)
  })
  if (thisRel)
    thisProject = projects.find(pr => thisRel.projectId.includes(pr._id))

  const handleFormFieldChange = (key, { target: { value } }) => {
    setDetails({ ...details, [key]: value })
  }
  /**
   * Check if the form fields from props.form are required agains the details object
   */
  const validateForm = () => {
    let valid = true
    props.form.forEach(k => {
      if (k.required && !details[k.k]) {
        toast(`${k.k} is required`)
        valid = false
      }
    })
    return valid
  }
  return (
    <Drawer
      isOpen={true}
      placement="right"
      isFullHeight={true}
      size={'md'}
      onClose={() => props.setOpenModal()}
      // finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">
          Edit {details ? details.firstName : ''} details{' '}
          <Button
            size={'sm'}
            mr={3}
            variant={view === 'info' ? 'solid' : 'outline'}
            onClick={() => setView('info')}
          >
            Info
          </Button>
        </DrawerHeader>

        <DrawerBody overflow={'auto'}>
          {props.form &&
            view === 'info' &&
            props.form.map((k, i) => {
              if (k.k === '_id') return null
              if (k.k === 'role' && props.types)
                return (
                  <InputGroup key={i} margin={'1em 0'} size="sm">
                    <InputLeftAddon
                      minWidth={'120px'}
                      textAlign={'center'}
                      padding={'1.5em .5em'}
                      children={k.l}
                      fontWeight={'bold'}
                    />
                    <Select
                      height="3em"
                      onChange={event => handleFormFieldChange(k.k, event)}
                      placeholder="Select option"
                      value={details[k.k]}
                    >
                      {props.types.map(
                        t =>
                          t.status === 1 && (
                            <option value={t._id}> {t.name}</option>
                          )
                      )}
                    </Select>
                  </InputGroup>
                )
              if (k.k === 'admin')
                return (
                  <InputGroup key={i} margin={'1em 0'} size="sm">
                    <InputLeftAddon
                      minWidth={'120px'}
                      textAlign={'center'}
                      padding={'1.5em .5em'}
                      children={k.l}
                      fontWeight={'bold'}
                    />
                    <Select
                      height="3em"
                      onChange={event => handleFormFieldChange(k.k, event)}
                      placeholder="Select option"
                      value={details[k.k]}
                    >
                      <option value={1}> Active </option>
                      <option value={0}> Disabled </option>
                    </Select>
                  </InputGroup>
                )
              if (k.k === 'status')
                return (
                  <InputGroup key={i} margin={'1em 0'} size="sm">
                    <InputLeftAddon
                      minWidth={'120px'}
                      textAlign={'center'}
                      padding={'1.5em .5em'}
                      children={k.l}
                      fontWeight={'bold'}
                    />
                    <Select
                      height="3em"
                      onChange={event => handleFormFieldChange(k.k, event)}
                      placeholder="Select option"
                      value={Number(details[k.k])}
                    >
                      <option value={0}> Disabled </option>
                      <option value={1}> Active </option>
                      <option value={2}> Allocated </option>
                    </Select>
                  </InputGroup>
                )
              if (k.t === 'textarea') {
                return (
                  <InputGroup key={i} margin={'1em 0'} size="sm">
                    <Textarea
                      padding={'1.5em .5em'}
                      onChange={event => handleFormFieldChange(k.k, event)}
                      placeholder={k.l}
                      type={k.t}
                      value={details[k.k]}
                    />
                  </InputGroup>
                )
              }
              if (k.t === 'dateArray') {
                return (
                  <>
                    <Text fontSize={'.8em'} fontWeight={'bold'}>
                      Indisponibilidade
                    </Text>
                  </>
                )
              }
              if (k.t === 'map') {
                return <div style={{ height: '300px' }}></div>
              }
              return (
                <InputGroup key={i} margin={'1em 0'} size="sm">
                  <InputLeftAddon
                    minWidth={'120px'}
                    textAlign={'center'}
                    padding={'1.45em .5em'}
                    children={k.l}
                    fontWeight={'bold'}
                  />
                  <Input
                    padding={'.5em .5em'}
                    height={'3em'}
                    onChange={event => handleFormFieldChange(k.k, event)}
                    type={k.t || 'text'}
                    placeholder={k.k}
                    value={details[k.k]}
                  />
                </InputGroup>
              )
            })}
        </DrawerBody>

        <DrawerFooter borderTopWidth="1px">
          <Button variant="outline" mr={3} onClick={() => props.setOpenModal()}>
            Cancel
          </Button>
          <Button
            variant="outline"
            onClick={() => {
              if (!validateForm()) return
              props.set(
                { ...details, location: location },
                ` updating user`,
                'users'
              )
              props.setOpenModal()
            }}
          >
            Save
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
