import React from 'react'
import Navigation from '../navigation'
import { useGlobalState } from '../../store'

export default props => {
  const [loggedIn, setLoggedIn] = useGlobalState('loggedIn')
  const [pageClass, setPageClass] = useGlobalState('pageClass')
  return (
    <div className={pageClass}>
      {loggedIn && pageClass !== 'login' && <Navigation {...props} />}
      <div>{props.children}</div>
    </div>
  )
}
