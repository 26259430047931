import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  Box,
  Button,
  InputLeftAddon,
  InputGroup,
  Select,
  Input,
  Textarea,
  Heading
} from '@chakra-ui/core'

import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton
} from '@chakra-ui/core'
import { getGlobalState, useGlobalState } from '../store'
// import Timeline from '../components/Timeline/Timeline'
import { toast } from 'react-toastify'

export default function Edit(props) {
  const [details, setDetails] = useState(props.data || {})
  const [view, setView] = useState('info')
  const projects = getGlobalState('projects')
  const [authors, setAuthors] = useGlobalState('users')

  const handleFormFieldChange = (key, { target: { value } }) => {
    setDetails({ ...details, [key]: value })
  }
  /**
   * Check if the form fields from props.form are required agains the details object
   */
  const validateForm = () => {
    let valid = true
    props.form.forEach(k => {
      if (k.required && !details[k.k]) {
        toast(`${k.k} is required`)
        valid = false
      }
    })
    return valid
  }
  return (
    <Drawer
      isOpen={true}
      placement="right"
      isFullHeight={true}
      size={'md'}
      onClose={() => props.setOpenModal()}
      // finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">
          Edit {details ? details.firstName : ''} details{' '}
          <Button
            size={'sm'}
            mr={3}
            variant={view === 'info' ? 'solid' : 'outline'}
            onClick={() => setView('info')}
          >
            Info
          </Button>
          <Button
            size={'sm'}
            variant={view === 'history' ? 'solid' : 'outline'}
            mr={3}
            onClick={() => {
              setView('history')
            }}
          >
            History
          </Button>
        </DrawerHeader>

        <DrawerBody overflow={'auto'}>
          {props.form &&
            view === 'info' &&
            props.form.map(k => {
              if (k.k === '_id') return null
              if (k.k === 'author' && authors)
                return (
                  <InputGroup margin={'1em 0'} size="sm">
                    <InputLeftAddon
                      minWidth={'120px'}
                      textAlign={'center'}
                      padding={'1.5em .5em'}
                      children={k.l}
                      fontWeight={'bold'}
                    />
                    <Select
                      height="3em"
                      onChange={event => handleFormFieldChange(k.k, event)}
                      placeholder="Select option"
                      value={details[k.k]}
                    >
                      {authors.map(
                        t =>
                          t.status === 1 && (
                            <option value={t._id}> {t.domain}</option>
                          )
                      )}
                    </Select>
                  </InputGroup>
                )
              if (k.k === 'type' && props.types)
                return (
                  <InputGroup margin={'1em 0'} size="sm">
                    <InputLeftAddon
                      minWidth={'120px'}
                      textAlign={'center'}
                      padding={'1.5em .5em'}
                      children={k.l}
                      fontWeight={'bold'}
                    />
                    <Select
                      height="3em"
                      onChange={event => handleFormFieldChange(k.k, event)}
                      placeholder="Select option"
                      value={details[k.k]}
                    >
                      {props.types.map(
                        t =>
                          t.status === 1 && (
                            <option value={t._id}> {t.name}</option>
                          )
                      )}
                    </Select>
                  </InputGroup>
                )
              if (k.k === 'status')
                return (
                  <InputGroup margin={'1em 0'} size="sm">
                    <InputLeftAddon
                      minWidth={'120px'}
                      textAlign={'center'}
                      padding={'1.5em .5em'}
                      children={k.l}
                      fontWeight={'bold'}
                    />
                    <Select
                      height="3em"
                      onChange={event => handleFormFieldChange(k.k, event)}
                      placeholder="Select option"
                      value={details[k.k]}
                    >
                      <option value={0}> Disabled </option>
                      <option value={1}> Active </option>
                      <option value={2}> Alocated </option>
                    </Select>
                  </InputGroup>
                )

              if (k.t === 'textarea') {
                return (
                  <>
                    <InputGroup margin={'1em 0'} size="sm">
                      <Textarea
                        padding={'1.5em .5em'}
                        onChange={event => handleFormFieldChange(k.k, event)}
                        placeholder={k.l}
                        type={k.t || 'text'}
                        value={details[k.k]}
                      />
                    </InputGroup>
                  </>
                )
              }
              if (k.t === 'datetime') {
                return (
                  <>
                    <InputGroup margin={'1em 0'} size="sm">
                      <InputLeftAddon
                        minWidth={'120px'}
                        textAlign={'center'}
                        padding={'1.45em .5em'}
                        children={k.l}
                        fontWeight={'bold'}
                      />
                      <Input
                        padding={'.5em .5em'}
                        height={'3em'}
                        onChange={event => handleFormFieldChange(k.k, event)}
                        type={'datetime-local'}
                        placeholder={k.l}
                        value={moment(details[k.k]).format('YYYY-MM-DDTHH:mm')}
                      />
                    </InputGroup>
                  </>
                )
              }
              return (
                <>
                  <InputGroup margin={'1em 0'} size="sm">
                    <InputLeftAddon
                      minWidth={'120px'}
                      textAlign={'center'}
                      padding={'1.45em .5em'}
                      children={k.l}
                      fontWeight={'bold'}
                    />
                    <Input
                      padding={'.5em .5em'}
                      height={'3em'}
                      onChange={event => handleFormFieldChange(k.k, event)}
                      type={k.t || 'text'}
                      placeholder={k.l}
                      value={details[k.k]}
                    />
                  </InputGroup>
                </>
              )
            })}
          {view === 'history' && <div>history</div>}
        </DrawerBody>

        <DrawerFooter borderTopWidth="1px">
          <Button variant="outline" mr={3} onClick={() => props.setOpenModal()}>
            Cancel
          </Button>
          <Button
            variant="outline"
            onClick={() => {
              if (!validateForm()) return
              props.set(details, ` updating resources`, 'resources')
              props.setOpenModal()
            }}
          >
            Save
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
