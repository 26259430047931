import React from 'react'
import MUIDataTable from 'mui-datatables'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'

const getMuiTheme = () =>
  createTheme({
    typography: {
      useNextVariants: true
    },
    overrides: {
      MuiTableBody: {
        root: {
          backgroundColor: '#ffffff'
        }
      },
      MUIDataTable: {
        root: {
          backgroundColor: '#FF000',
          maxHeight: '100%',
          height: '80vh'
        },
        paper: {
          boxShadow: 'none',
          background: 'transparent'
        },
        responsiveScroll: {
          maxHeight: 'none',
          background: '#fff',
          borderRadius: '4px',
          boxShadow:
            '0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15)'
        }
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          zIndex: 9
        }
      }
    }
  })

function TableProvider(props) {
  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable {...props} />
    </MuiThemeProvider>
  )
}

export default TableProvider
