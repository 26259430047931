import React, { useEffect, useState } from 'react'
import Header from '../components/header'
import Edit from './Edit'
import { Grid, Box, Button, Stack } from '@chakra-ui/core'
import { useGlobalState, setGlobalState } from '../store'
import { setRemoteCall } from '../remote'
import TableProvider from '../components/Table/Table'
import { AiOutlineMail } from 'react-icons/ai'
/**
 * Table collumns
 */
export const tableCollumns = [
  { k: '_id', l: '_id' },
  { k: 'url', l: 'URL', required: true },
  { k: 'domain', l: 'Domain', required: true },
  { k: 'status', l: 'Estado', required: true },
  { k: 'fields', l: 'Fields' },
  { k: 'date', l: 'Data' }
  // { k: 'attr', l: 'Atributos' },
]

// fields: Object,
// url: String,
// domain: String,
// attr: Object,
// date: Date,
// lastUpdate: Date,
// status: Number

export default function Messages(props) {
  /**
   * Get Data
   */
  const [contactsURL, setContactsURL] = useState([])
  const [selectedContactsURL, setSelectedContactsURL] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [showAll, setShowAll] = useState(false)
  const [data, setData] = useGlobalState('messages')
  //set the initical class name
  setGlobalState('pageClass', 'messages')

  let collumns = tableCollumns

  useEffect(() => {
    const checkSingleUrl = () => {
      const urlData = data.map(item => item.url)
      setContactsURL(new Set(urlData))
    }
    checkSingleUrl()
    return () => {}
  }, [data])

  /**
   * Table data
   */

  let cf = [] //cf check
  let users =
    data &&
    data
      .filter(item => item.url === selectedContactsURL)
      .map(row => {
        let cfKeys = { ...row }
        Object.keys(row).map(key => {
          if (row[key] instanceof Object && Object.keys(row[key]).length > 0) {
            //lets take care of the collumns
            let tempCollumns = [
              ...Object.keys(row[key]).map(k => {
                if (cf.includes(k)) return null
                cf.push(k)
                return {
                  k: `${key}_${k}`,
                  name: `${k}`,
                  l: `${key}_${k}`,
                  options: {
                    // display: showAll,
                    draggable: true
                  }
                }
              })
            ]
            //remove null columns and sort
            collumns = [
              ...collumns,
              ...(tempCollumns
                .filter(v => v !== null)
                ?.sort((a, b) => {
                  if (a.name && b.name) {
                    if (a.name < b.name) return -1
                    if (a.name > b.name) return 1
                  }
                  return 0
                }) || [])
            ]
            //now lets insert data
            Object.keys(row[key]).map(k => {
              if (
                row[key][k] instanceof Object &&
                Object.keys(row[key][k]).length > 0 &&
                row[key][k].value
              ) {
                cfKeys[`${key}_${k}`] = row[key][k].value
              } else {
                cfKeys[`${key}_${k}`] = row[key][k]
              }
            })
          }
        })
        return cfKeys
      })

  /**
   * Table options
   */

  const options = {
    filter: true,
    filterType: 'dropdown',
    count: users ? users.length : 0,
    tableBodyHeight: 'calc( 100vh - 260px )',
    rowsPerPageOptions: [10, 15, 100, 1000, 10000],
    draggableColumns: {
      enabled: true
    },
    downloadOptions: {
      filename: 'report.csv',
      separator: ';',
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true
      }
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      const selectedIds = selectedRows.data.map(
        sel => users[sel.dataIndex] && users[sel.dataIndex]._id
      )

      const actionType = 'delMessages'
      const actionMessage = `Deleted Message`

      return (
        <div className="table-actions">
          <Button
            variantColor="red"
            onClick={() =>
              setRemoteCall(actionType, selectedIds, actionMessage)
            }
          >
            Delete Selected
          </Button>
        </div>
      )
    },
    onRowClick: (rowData, rowMeta) => {
      setOpenModal(users[rowMeta.dataIndex])
    }
  }

  /**
   * Set table data
   */
  const set = (data, notice) => {
    setRemoteCall('setMessages', data, notice)
  }

  return (
    <div className={'table-wrapper'}>
      <Box className={'content'} padding={'0 1em'}>
        <Header>
          {/* <button onClick={() => props.socket.emit('page', 'games')}>
          Isto é o que está a acontecer hoje
        </button> */}
          <Grid templateColumns="repeat(2, 1fr)" gap={6}>
            <Box w="100%">
              <h1>Messages</h1>
            </Box>
            {
              <Box
                w="20%"
                h="10"
                // placeItems={'end'}
                // display={'flex'}
                fontSize={'.8em'}
              >
                <Button
                  active={props.periodType === 'week'}
                  marginLeft={'1%'}
                  size={'sm'}
                  onClick={() => setSelectedContactsURL(null)}
                >
                  <span className={`text`}>Back</span>
                </Button>
                {/* <Button
                  active={props.periodType === 'week'}
                  variantColor="green"
                  size={'sm'}
                  marginLeft={'1%'}
                  onClick={() => setShowAll(!showAll)}
                >
                  <span className={`text`}>
                    {showAll ? 'Esconder Campos' : 'Todos os Campos'}
                  </span>
                </Button> */}
              </Box>
            }
          </Grid>
        </Header>

        {!selectedContactsURL && contactsURL && (
          <div className={'contentTableMap'}>
            <div className={'tableWrapper'}>
              <Stack flexDir={'row'} flexWrap={'true'} padding={'5vh 0'}>
                {[...contactsURL].map((url, index) => {
                  //get last folder of url
                  const urlName = url.split('/').pop()

                  return (
                    <Box
                      onClick={() => setSelectedContactsURL(url)}
                      width={'25%'}
                      boxShadow={'0 0 10px rgba(0,0,0,0.2)'}
                      borderRadius={'5px'}
                      padding={'1em'}
                      wordBreak={'break-all'}
                      margin={'1em'}
                      cursor={'pointer'}
                      textAlign={'center'}
                      fontWeight={'bold'}
                      background={'#fff'}
                      _hover={{
                        background: 'rgba(0,0,0,1)',
                        color: 'white'
                      }}
                    >
                      <Box display={'inline-block'}>
                        <AiOutlineMail size={40} />
                      </Box>
                      <h3>{urlName || 'home'}</h3>
                    </Box>
                  )
                })}
              </Stack>
            </div>
          </div>
        )}

        {users && selectedContactsURL && (
          <div className={'contentTableMap'}>
            <div className={'tableWrapper'}>
              <TableProvider
                data={users.filter(item => item.url === selectedContactsURL)}
                columns={collumns.map(key => {
                  if (key.k === 'status') {
                    return {
                      name: key.k,
                      label: key.l,
                      selector: key.k,
                      options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                          switch (Number(value)) {
                            case 0:
                              return 'Disabled'
                            case 1:
                              return 'Active'
                            case 2:
                              return 'Allocated'
                          }
                        }
                      }
                    }
                  }
                  if (key.k === 'fields') {
                    return {
                      name: key.k,
                      label: key.l,
                      selector: key.k,
                      options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                          if (value) {
                            return Object.keys(value).map(key => {
                              if (typeof value[key] !== 'object') {
                                return (
                                  <div key={key}>
                                    {key} :{' '}
                                    {value[key] === true
                                      ? 'accepted'
                                      : value[key]}
                                  </div>
                                )
                              } else {
                                return Object.keys(value[key]).map(a => {
                                  return (
                                    <div key={a}>
                                      {a} :{' '}
                                      {value[key][a] === true
                                        ? 'accepted'
                                        : value[key][a]}
                                    </div>
                                  )
                                })
                              }
                            })
                          }

                          return ''
                        }
                      }
                    }
                  }

                  return {
                    name: key.k,
                    label: key.l,
                    selector: key.k,
                    options: {
                      filter: true,
                      sort: true,
                      display:
                        key.k !== '_id' &&
                        key.k !== 'availability' &&
                        key.k !== 'location' &&
                        key.k !== 'password' &&
                        key.k !== '__v',
                      ...(key.options || {})
                    }
                  }
                })}
                options={options}
              />
            </div>
          </div>
        )}
        {openModal && (
          <Edit
            data={openModal}
            form={collumns}
            set={set}
            setOpenModal={setOpenModal}
          />
        )}
      </Box>
    </div>
  )
}
